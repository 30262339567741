import React from 'react'
import Headers from '../components/UI/Headers'
import Button from '../components/UI/Button'

export default function Home() {
  return (
    <div className="flex flex-col items-center lg:h-screen overflow-hidden px-4">
      <Headers title="Cabines photo Harcourt" />
      <a href="https://www.studio-harcourt.com/">
        <img src="/logo.jpeg" className="h-24 lg:h-32 my-8 mr-2"/>
      </a>
      <div className="text-xl lg:text-3xl font-thin text-gray-800 uppercase mb-6">La cabine photo de luxe</div>
      <Button onClick={() => {window.location.href = "mailto:contact@rpsolutions.fr?subject=Demande%20Harcourt"}}>Contacter un commercial</Button>
      <div className="flex flex-col lg:flex-row justify-center items-center h-full w-full py-20">
        <img src="/cabine.png" className="full mx-10 max-w-xs lg:max-w-sm" />
        <iframe className="border border-gray-600 p-3 w-full max-w-3xl mt-10 h-full" style={{ maxHeight: 800, minHeight: 500 }} frameBorder="0" src="https://booths-map.netlify.app/?data=b2071b92a9ce0a3cc49b9b7e4073cf650a2c44a9" />
      </div>
    </div>
  )
}
